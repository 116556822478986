@import "variables";

.bg-lightyellow{
background-color: rgba($yellow, 0.15);
}

.bg-yellow{
    background-color: $yellow;
    }

.main-red{
    color: $red;
}

.main-green {
    color: $green;
}

.main-yellow{
    color: $yellow;
}

.bg-leftnav {
    background-color: white;
}

.link-yellow {
    color: $link;
}

.red-border{
    border-color: $red !important;
}


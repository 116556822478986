$yellow: #d8a428;
$link: darken($yellow, 3);
$white: #ffffff;
$nickel: #787673;
$davys-gray: #605e5b;
$emphasis: #adb5bd;
$black: #000000;
$red: #f25454;
$top-nav-height: 105px;
$green: #1f6e43;
$kendo-color-primary: #d8a428 !default;
$kendo-grid-sorting-indicator-text: #d8a428 !default;
$kendo-selected-bg: #d8a428 !default;
$kendo-link-text: $kendo-color-primary !default;
$kendo-drop-hint-bg: $kendo-color-primary !default;

// kendo theaming
// colors
$kendo-component-color: $davys-gray;
$kendo-color-primary:$yellow; 
$primary:$yellow; 
$kendo-color-secondary: $white;
$secondary: $white;
$kendo-color-info: $davys-gray;
$info: $davys-gray;
$kendo-accent: $nickel;

// buttons
$kendo-button-focused-shadow: none;
$kendo-button-text: $davys-gray;
$kendo-btn-padding-x: 40px;
$kendo-button-border: transparent;
$kendo-btn-border-width: 0px;
$kendo-primary-button-text:  $davys-gray;
$kendo-button-md-padding-x:40px;

// texts
$kendo-base-text: $davys-gray;
$kendo-selected-text: $davys-gray;

// grid
$kendo-grid-header-bg: transparent;
$kendo-grid-bg: transparent;
$kendo-grid-alt-bg: transparent;
$kendo-grid-footer-border: transparent;
$kendo-header-text: $davys-gray;
$kendo-table-header-bg: transparent;
$kendo-grid-border-width: 0px;
// component
$kendo-component-bg: $white;
$kendo-component-text: $davys-gray;
$kendo-component-border-color: transparent;

// common
$kendo-base-border: transparent;
$kendo-component-border: transparent;
$kendo-tabstrip-border: transparent;
$kendo-table-border: transparent;
$kendo-grid-border: transparent;
$kendo-tabstrip-item-border: transparent;
$kendo-tabstrip-border: transparent;
$kendo-tabstrip-border: transparent;
$kendo-input-border-color: transparent;
$kendo-switch-off-bg: $nickel;
$kendo-grid-cell-vertical-border-width: 0;
$kendo-grid-cell-horizontal-border-width: 0;
$kendo-table-cell-horizontal-border-width:0;
$kendo-table-cell-vertical-border-width: 0;
$kendo-table-border: transparent;

//$switch-size: 5rem;;
//$font-size-xs: 1rem;

// tabstrip
$nav-link-color: $davys-gray;
$link-color: $davys-gray;
$kendo-link-text: $davys-gray;
$kendo-nav-tabs-border-color: transparent;
$kendo-nav-tabs-link-active-bg: transparent;
$kendo-tabstrip-content-bg: transparent;
$kendo-nav-tabs-link-hover-border-color: transparent;
$kendo-tabstrip-content-padding-x: 0;
$kendo-toolbar-bg: transparent;

// bootstrap overrides
$kendo-breadcrumb-bg: transparent;
$kendo-breadcrumb-active-color: rgba($yellow, 1);
$kendo-link-color: $davys-gray;

//switch
$kendo-switch-handle-size: 1.5rem;
$kendo-switch-size: 3rem;
$_track-width: 3rem;


$kendo-enable-color-system: true;
$kendo-input-bg: $white;
$kendo-input-focus-bg: $white;
$kendo-component-bg: transparent;
$kendo-link-text: $link-color !default;
$link-color: $davys-gray;
$blue:  $davys-gray;
$kendo-pager-bg: transparent;
$_button-hover-bg: transparent;
$kendo-list-bg: $white;
$kendo-list-border: $white;
$kendo-window-bg: $white;
$kendo-invalid-text: red;

$kendo-colors: (
   
	primary-subtle: $davys-gray,
	primary-hover: 	$yellow,
	primary: $yellow,
    on-primary: $davys-gray,
	primary-on-surface: $yellow,
	secondary: $white,
	
	surface-alt: #787673,
	info: $davys-gray,
	base-hover: #adb5bd,
    base-emphasis: $emphasis,
	component-bg: transparent,
);

@import "variables";
#main {
.gradient-btn{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), $yellow;
    background-blend-mode: overlay, normal;
    border: none;
    &:hover{
        color: white !important;
    }
}

.main-size-btn {
    
    height: 45px;
}

.modal-buttons{
    text-align: right;
    margin-top: 15px;
}


.bottom-buttons {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: white;
    padding: 10px 20px;
  }
    
    .k-button.k-primary.k-outline {
        &:not(.inner-form-button) {
            background: white;
        }
    }
}
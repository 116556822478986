@import "variables";
@import "colors";

$min-input-height: 54px;

html,
body {
  .k-notification-group {
    z-index: 100;
  }
}

.k-state-disabled {
  &span,
  &input,
  &textarea {
    opacity: 1;
    filter: grayscale(0);
  }
}

.k-grid-add-row,
.k-grid-edit-row {
  td > {
    textarea,
    input {
      //border: 1px solid rgba($davys-gray, 0.4);
      border-radius: 5px;
    }

    input.ng-valid {
      border: 1px solid rgba($davys-gray, 0.4);
    }
  }
}

.k-upload .k-dropzone {
  min-height: $min-input-height;
}
.app-spinner.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: $yellow;
  z-index: 9;
  margin: auto;
}

.k-upload-files {
  border: none;
}

.k-loading-pdf-mask {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.k-form-field {
  margin: 0;
}

.k-form-error {
  color: white;
  background-color: red;
  min-width: 150px;
  display: flex;
  justify-content: center;
  padding: 6px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 10px;
}

.k-filtercell-wrapper {
  align-items: center;
}

.k-textbox,
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  //border: none;
  background-color: $white;
  color: $davys-gray;
  border-color: transparent;
}

.col-label {
  .k-textarea {
    &:not(.ng-invalid) {
      border: none;
    }
  }
}

.content .k-floating-label-container {
    padding-top: 0px;
     margin: 1.5px 0px;
  .k-input-inner {
    background: transparent;
    min-height: 54px;
  }
  .k-picker-solid{
    background: transparent;
    &:focus-within, &:hover, &:focus{
        background: transparent;
        border-color:  transparent;
        border: none;
        box-shadow: none;
        min-height: 54px;
    }    
  }
  textarea, input, kendo-numerictextbox, kendo-datepicker{
    border: none;
    min-height: 54px;    
  }
  textarea, input {
    padding: 1.3rem 1rem 1rem .5rem;
  }
  .k-input-values{
    padding: 1.3rem 1rem .2rem .5rem
  }

  kendo-numerictextbox{
    min-height: 46px;
  }

  kendo-combobox{
    width: 100%;
  }

    .k-button-solid-base{
        background: transparent;
        &:hover{
            background:$emphasis;
        }
    }

    .k-combobox  .k-button-solid-base {
        border-color: transparent;
        color: #605e5b;
        background-color: #e4e7eb
    }

    .k-combobox  .k-button-solid-base:hover {
        border-color: #c7cdd5;
        background-color: #ced3db
    }

    &.k-empty {
        > .k-floating-label {
            
            font-size: 1em;
        }
    }

    > .k-floating-label,
    &.k-focus > .k-floating-label {       
        font-size: 0.7em;
        top: 0.3rem;
        left: 0.5rem;
    }
    &:focus-within > .k-floating-label {
      //todo
    }

    kendo-switch {
        margin: 1.6rem 1rem .4rem .5rem;
        max-width: 60px;
    }
}

.k-button {
  &.k-primary {
    border-width: 0;
  }

  &.k-outline {
    border: 2px solid;
  }

  &:hover {
    color: white;
  }
}

.k-button.k-outline {
  &:hover {
    color: $link;
  }
}

.k-grid-aria-root {
  //overflow: visible !important;
  .k-grid-header {
    .k-grid-header-wrap {
      //overflow: visible !important;
      th {
        padding-bottom: 0;

        .k-column-title {
          color: rgba(99, 96, 94, 0.5);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .k-grid-container {
    //overflow: visible !important;
    .k-grid-content {
      //overflow: visible !important;
      //overflow-y: hidden;
      overflow-y: auto;
      padding: 0 10px;

      .k-grid-table {
        border-collapse: separate;
        border-spacing: 0 3px;

        tbody {
          tr {
            background-color: $white;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 5px;

            &.archived {
              background-color: rgb(216, 164, 40, 0.3);
            }

            td {
              font-weight: 500;
            }

            &.error-row {
              td {
                background-color: $red !important;
              }
            }

            & {
              td:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              td:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }

            &.k-grouping-row {
              background-color: black;

              p {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.k-pager-numbers {
  border: none;

  .k-link {
    margin: 0 6px;
    padding: 0px 8px !important;
    border: none !important;
  }
}

.k-pager-refresh,
.k-pager-nav.k-link {
  border: none;
  background-color: transparent !important;
}

.k-tabstrip-top {
  > .k-tabstrip-items {
    .k-item {
      font-weight: 500;

      &.k-state-active {
        border-bottom: 2px solid $yellow;
      }
    }
  }
}

.k-floating-label-container {
  border-radius: 5px;
}

// .k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible) {
//   display: none;
// }

// .k-filter-row td,
// .k-filter-row th {
//   padding-right: 0px;
// }

.k-grid,
.k-grid .k-grid-content,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked,
.k-grid td {
  border-color: transparent;
}

.k-grid .k-alt {
  background-color: transparent;
}

.k-grid .k-grid-header .k-table-th,
.k-table-th,
.k-grid .k-grid-header {
  border-block-end-width: 0px;
}
.k-grid .k-grid-aria-root .k-grid-header {
  border-bottom-width: 0px;
}
.k-form-field-wrap .k-editor {
  background-color: white;
}
.k-child-animation-container .k-popup.k-list-container.k-dropdownlist-popup {
  border: none;
}
.k-list-ul .k-list-item.k-selected:hover {
  background-color: $primary;
}

.k-button-solid-primary:hover {
  border-color: $davys-gray;
  background-color: $nickel;
}

.k-floating-label-container .k-picker {
  border: none;
}

.content{ 
    .k-form-error{
        color: $white;
        justify-content: center;
    }
    
}

.k-popup.k-multiselecttree-popup{
    background-color: #fff;
}
input.k-checkbox{
    background-color: #fff;
}